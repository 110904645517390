import { useRouter } from 'next/router';
import addDays from 'date-fns/addDays';
import {
  useCookies, COOKIES_KEY, ReferrerCookieType,
} from '@/utils/cookies';

const useReferrer = () => {
  // NOTE : might not use it anymore
  const { query } = useRouter();
  const [cookies, setCookies] = useCookies([COOKIES_KEY.REFERRER]);
  const saveReferrerCookie = () => {
    const referrer = (query?.ref != null && typeof query.ref !== 'string') ? query.ref[0] : query.ref;
    if (referrer && referrer !== (cookies[COOKIES_KEY.REFERRER] as ReferrerCookieType)?.referrer) {
      // cookie expire in 7 days
      setCookies(COOKIES_KEY.REFERRER, {
        referrer,
        isFromUrl: true,
      }, { expires: addDays(new Date(), 7) });
    }
  };

  return { saveReferrerCookie };
};

export default useReferrer;

import React, {
  useImperativeHandle,
  useState,
  useEffect,
  createRef,
  forwardRef,
} from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { sleep } from '../../utils/time';
import onInputFocusState from '@/recoils/modules/onInputFocus';
import { InputDigitHandles, InputDigitProps } from './InputDigit.type';
import Styled from './styled';

const ERROR_DELAY = 1.5;
const animations = {
  inputErrorShake: {
    x: [0, 5, -5, 0],
    transition: { repeat: 2, duration: 0.15 },
  },
  showErrorMessage: {
    opacity: 1,
    height: '30px',
    transition: { duration: 0.2 },
  },
  hideErrorMessage: {
    opacity: 0,
    height: '0px',
    transition: { duration: 0.2 },
  },
};

const InputDigit = forwardRef<InputDigitHandles, InputDigitProps>(({
  id = 'digit-input',
  total = 6,
  label,
  disabled = false,
  color,
  onComplete,
}, ref) => {
  const [digits, setDigits] = useState<string[]>([]);
  const [focusIndex, setFocusIndex] = useState<number>(0);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isInputError, setIsInputError] = useState<boolean>(false);
  const [isErrorAnimation, setIsErrorAnimation] = useState<boolean>(false);
  const [shouldErrorMessageShow, setShouldErrorMessageShow] = useState<boolean>(false);
  const [inputRefs, setInputRefs] = useState<React.RefObject<HTMLInputElement>[]>([]);
  const [, setOnFocusInput] = useRecoilState(onInputFocusState);
  const { t } = useTranslation(['common']);

  const error = async (message?: string) => {
    if (message) {
      setErrorMessage(message);
      setShouldErrorMessageShow(true);
    }
    setIsInputError(true);
    setIsErrorAnimation(true);
    setDigits([]);
    setFocusIndex(0);
    await sleep(ERROR_DELAY);

    setIsInputError(false);
    setIsErrorAnimation(false);
    inputRefs[0].current?.focus();
  };

  const errorWithOutAnimation = async (message?: string) => {
    if (message) {
      setErrorMessage(message);
      setShouldErrorMessageShow(true);
    }
    setIsInputError(true);
    setDigits([]);
    setIsInputError(false);
  };

  const clearInput = () => {
    inputRefs[0].current?.focus();
    setDigits([]);
    setFocusIndex(0);
  };

  const clearError = () => {
    setErrorMessage('');
    setShouldErrorMessageShow(false);
  };

  useImperativeHandle(ref, () => ({
    error,
    clearInput,
    clearError,
    errorWithOutAnimation,
  }));

  useEffect(() => {
    setInputRefs((newInputRefs) => (
      Array(total).fill(null).map((_, i) => newInputRefs[i] || createRef())
    ));
  }, [total]);

  useEffect(() => {
    if (onComplete && digits.length >= total) {
      onComplete(digits.join(''));
    }
  }, [digits]);

  const onInputFocus = () => {
    setOnFocusInput(true);
  };
  const onInputBlur = () => {
    setOnFocusInput(false);
  };

  return (
    <Styled.InputDigit
      className="ps-input-digit"
      onClick={() => {
        // resume focus
        if (!isInputError && focusIndex < inputRefs.length) {
          inputRefs[focusIndex].current?.focus();
        }
      }}
    >
      <Styled.Label data-testid={`${id}__label`} className="ps-input-digit__label">
        {label}
      </Styled.Label>
      <Styled.Form
        variants={animations}
        animate={isErrorAnimation ? 'inputErrorShake' : ''}
      >
        <Styled.Input className="ps-input-digit__input" color={color}>
          {new Array(total).fill(undefined).map((_, index) => {
            return (
              <div
                className="position-relative"
                key={`${id}-${index}`}
                id={`${id}-${index}`}
              >
                <Styled.Dot
                  className="ps-input-digit__dot"
                  data-testid={`${id}-dot-${index}`}
                  style={{ display: index < focusIndex - 1 ? 'initial' : 'none' }}
                >
                  ●
                </Styled.Dot>
                <Styled.InputItem
                  ref={inputRefs[index]}
                  maxLength={1}
                  value={index === focusIndex - 1 ? digits[index] || '' : ''}
                  data-testid={`${id}-${index}`}
                  disabled={disabled}
                  autoComplete="off"
                  inputMode="numeric"
                  type="tel"
                  onFocus={onInputFocus}
                  onBlur={onInputBlur}
                  className={classNames('ps-input-digit__input-item', { 'ps-input-digit__input-item--error': isInputError })}
                  onChange={(e) => {
                    const regex = new RegExp(/[^0-9]/, 'g');
                    const { value } = e.target;
                    if (value.match(regex) || value === '') {
                      setErrorMessage(t`common:input.error.nonDigit`);
                      setShouldErrorMessageShow(true);
                      return;
                    }
                    setShouldErrorMessageShow(false);

                    const newDigits = [...digits];
                    newDigits[index] = value;
                    setDigits(newDigits);

                    // focus next input
                    setFocusIndex(index + 1);
                    if (index < total - 1) {
                      inputRefs[index + 1].current?.focus();
                    } else {
                      inputRefs[index].current?.blur();
                    }
                  }}
                  onKeyDown={(e) => {
                    // handle delete
                    if ((e.key === 'Backspace' || e.key === 'Delete') && index > 0) {
                      const newDigits = [...digits];
                      newDigits[index - 1] = '';
                      setDigits(newDigits);
                      setFocusIndex(index - 1);
                      inputRefs[index - 1].current?.focus();
                    }
                  }}
                  onMouseDown={(e) => {
                    // prevent manual focus
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                  onPaste={(e) => {
                    // prevent paste
                    e.preventDefault();
                    return false;
                  }}
                />
              </div>
            );
          })}
        </Styled.Input>
        <Styled.Alert
          data-testid={`${id}-error-msg`}
          className="ps-input-digit__alert"
          variants={animations}
          animate={shouldErrorMessageShow ? 'showErrorMessage' : 'hideErrorMessage'}
        >
          <span className="ps-input-digit__alert-icon">
            <div className="ps-icon-info" />
          </span>
          <span data-testid={`${id}-error-msg-label`} className="ps-input-digit__alert-message">
            {errorMessage}
          </span>
        </Styled.Alert>
      </Styled.Form>
    </Styled.InputDigit>
  );
});

InputDigit.displayName = 'InputDigit';

export default InputDigit;

import { useRecoilState } from 'recoil';
import { AxiosRequestConfig } from 'axios';
import { getProfile } from '@/services/profile';
import { COOKIES_KEY, useCookies } from '@/utils/cookies';
import { profileState } from '../recoils';
import { useAxios } from './useAxios';
import { fetchWithJSON } from '@/utils/api/fetch';
import { BankCodeName } from '@/models/shared-types';
import { BaseApiResponse } from '@/services/service.type';
import { useRouter } from 'next/router';
import isEmpty from 'lodash/isEmpty';
import NiceModal from '@ebay/nice-modal-react';
import { AlertContactModalV2 } from '@/components/Modal/AlertContactModal/AlertContactModal';
import { decodeAuthToken } from '@/utils/jwt';

export type WithToken = {
  bearer_token: string;
};

export type UpdateProfileRequest = Partial<{
  user_code: string;
  first_name: string;
  last_name: string;
  phone_number: string;
  identity_number: string;
  back_identity_number: string;
  bank_code: BankCodeName;
  bank_account_number: string;
  bank_account_name: string;
  bank_account_name_en: string;
  default_group_bank_code: string;
  default_bgb_deposit_bank_code: string;
  status: string;
  true_wallet_id: string;
  avatar: string;
  display_name: string;
  user_pin: string;
  line_user_id: string;
}>;

export type LinkLineRequest = Partial<{
  line_id_token: string;
  user_code: string;
}>;

export type UpdateProfileResponse = BaseApiResponse<{
  bank_account_existed: boolean;
  is_display_name_has_locked: boolean;
  is_duplicate_display_name: boolean;
  line_user_id_existed: boolean;
  phone_number_existed: boolean;
  true_wallet_id_existed: boolean;
}>;

export const useProfile = () => {
  const { pigspinApiInstance } = useAxios();
  const router = useRouter();
  const [cookies] = useCookies<string>([COOKIES_KEY.CFID]);
  const authData = decodeAuthToken(cookies.cfid || '');
  const [, setProfile] = useRecoilState(profileState);

  const callProfile = async (token?: string) => {
    try {
      const profileResponse = await getProfile({
        query_by: 'USER_CODE',
        user_code: authData.user_uid,
        phone_number_pattern: '********00',
        id_card_pattern: '*********0000',
        back_id_card_pattern: '**********00',
        bank_account_number_10_digits_pattern: '******0000',
        bank_account_number_12_digits_pattern: '********0000',
        token: token || cookies.cfid || '',
      });

      if (isEmpty(profileResponse)) throw new Error();
      setProfile({
        data: {
          ...profileResponse,
        },
      });
      return profileResponse;
    } catch (err) {
      NiceModal.show(AlertContactModalV2, {
        header: 'มีบางอย่างผิดพลาด',
      });
      router.push('/logout');
      return null;
    }
  };

  const callUpdateProfile = async (request: UpdateProfileRequest) => {
    const requestConfig: AxiosRequestConfig = {
      url: '/v1/user/profile',
      method: 'PUT',
      data: {
        user_code: authData.user_uid,
        ...request,
      },
    };
    const response = await fetchWithJSON<UpdateProfileResponse>(pigspinApiInstance, requestConfig);
    return response;
  };

  const callUpdateProfileWithoutAuth = async (request: UpdateProfileRequest & WithToken) => {
    const { bearer_token, ...rest } = request;
    const requestConfig: AxiosRequestConfig = {
      url: '/v1/user/profile',
      method: 'PUT',
      data: {
        ...rest,
      },
      headers: {
        authorisation: bearer_token,
      },
    };
    const response = await fetchWithJSON<UpdateProfileResponse>(pigspinApiInstance, requestConfig);
    return response;
  };

  const callLinkLine = async (line_id_token?: string) => {
    const requestConfig: AxiosRequestConfig = {
      url: '/v1/user/line-management',
      method: 'PUT',
      data: {
        line_id_token,
        user_code: authData.user_uid,
      },
    };
    const response = await fetchWithJSON<BaseApiResponse<any>>(pigspinApiInstance, requestConfig);
    return response;
  };

  const callLinkLineWithoutAuth = async (request: LinkLineRequest & WithToken) => {
    const { bearer_token, ...rest } = request;
    const requestConfig: AxiosRequestConfig = {
      url: '/v1/user/line-management',
      method: 'PUT',
      data: {
        ...rest,
      },
      headers: {
        authorisation: bearer_token,
      },
    };
    const response = await fetchWithJSON<BaseApiResponse<any>>(pigspinApiInstance, requestConfig);
    return response;
  };

  return {
    callProfile,
    callUpdateProfile,
    callUpdateProfileWithoutAuth,
    callLinkLine,
    callLinkLineWithoutAuth,
  };
};

// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Using_promises
export function sleep(second: number): Promise<void> {
  if (second <= 0 || Number.isNaN(second)) {
    return Promise.resolve();
  }
  return new Promise((resolve) => setTimeout(resolve, second * 1000));
}

export const stringTimezone = (
  data: string | number,
) => {
  try {
    const timezoneValue = parseInt(data.toString());
    if (timezoneValue > 0) {
      return `+${timezoneValue}`;
    } if (timezoneValue < 0) {
      return `-${timezoneValue}`;
    }
    return '0';
  } catch (e) {
    return '0';
  }
};

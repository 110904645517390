import styled from 'styled-components';
import { screen } from '@/utils/responsive';
import { motion } from 'framer-motion';

const StyledInputDigit = {
  InputDigit: styled.div`
  `,
  Form: styled(motion.div)`
  `,
  Label: styled.div`
    color: ${({ theme }) => theme['text-1']};
    text-align: center;
    font-size: 20px;
    font-weight: 400;
  `,
  Input: styled.div<{ color?: string }>`
    display: flex;
    justify-content: center;
    color: ${({ color, theme }) => color || theme['text-1']};

    div:first-child > input {
      margin-left: 0;
      margin-right: 4px;
    }

    div:last-child > input {
      margin-left: 4px;
      margin-right: 0;
    }
  `,
  Dot: styled.div`
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
  `,
  InputItem: styled.input`
    background-color: transparent;
    color: inherit;
    font-size: 32px;
    border: ${({ theme }) => `2px solid ${theme['greyscale-400']}`};
    caret-color:   ${({ theme }) => `${theme['greyscale-400']}`};
    border-radius: 8px;
    margin: 0px 4px;
    text-align:center;
    outline: none;

    width: 42px;
    height: 49px;

    @media ${screen.sm} {
      width: 48px;
      height: 56px;
    }
    &:disabled {
      opacity: 0.5;
      border: none;
    }
    &[class$="--error"] {
      border: ${({ theme }) => `2px solid ${theme['red-dark']}`};
    }
    &:focus {
      border: 2px solid ${({ theme }) => theme['primary-400']};
      caret-color: ${({ theme }) => theme['primary-400']};
    }
  `,
  Alert: styled(motion.div)`
    color: ${({ theme }) => theme['red-dark']};
    text-align: center;
    font-weight: 400;
    font-size: 20px;
    margin-top: 4px;
    height: 0px;
    opacity: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    &-icon {
      font-size: 15px;
      margin: 0px 4px;
      line-height: 0px;
    }
  `,
};

export default StyledInputDigit;

export enum ALERT_MODAL_TYPE {
  BANK_ACCOUNT_DUPLICATE = 'bank-account-duplicate',
  BANK_ACCOUNT_MISMATCH_WITH_NAME = 'bank-account-mismatch-with-name',
  PIN_LOCKED = 'pin-locked',
  TOO_MANY_OTP_REQUEST = 'too-many-otp-request',
  TOO_MANY_OTP_REQUEST_OR_ATTEMPT = 'too-many-otp-request-or-attempt',
  TOO_MANY_OTP_ATTEMPT = 'too-many-otp-attempt',
  UNEXPECTED_BEHAVIORS = 'unexpected-behaviors',
  CLOSE_REGISTER = 'close-register',
  OTP_SENT = 'otp-sent',
  RESET_PIN_CHECK = 'reset-pin-check',
  KYC_ALREADY_EXIST_CID = 'kyc-already-exist-cid',
  KYC_INVALID_CID = 'kyc-invalid-cid',
  KYC_INVALID_LASER_ID = 'kyc-invalid-laser-id',
  KYC_INVALID_INFO = 'kyc-invalid-info',
  KYC_UNEXPECTED_BEHAVIORS = 'kyc-unexpected-behaviors',
  DUPLICATED_ACCOUNT = 'duplicated-account',
  DATA_NOT_FOUND = 'data-not-found',
  CONTACT_US = 'contact-us',
  CONFIRM_PIN_DOES_NOT_MATCH = 'confirm-pin-does-not-match',
  BANNED_USER = 'banned-user',
  LOCKED_ACCOUNT = 'lock-account',
  SOMETHING_WENT_WRONG = 'something-went-wrong',
  CHANGE_PIN_SUCCESS = 'change-pin-success',
  CASH_BALANCE_NOT_ENOUGH = 'cash-balance-not-enough',
  REQUIRE_PLAY_GAME = 'require-play-game',
  PHONE_DUPLICATE = 'phone-duplicate',
  EXPIRED_CARD = 'expired-card',
  PHYSICAL_REWARD_REDEEM_ERROR = 'physical-reward-redeem-error',
  WALLET_REVOKED = 'wallet-revoked',
  WALLET_CANCELED = 'wallet-canceled',
  REFERRER_NOT_VALID = 'referrer-not-valid',
  CLOSE_WITHDRAW = 'close-withdraw',
}

export type AlertModalProps = {
  type: ALERT_MODAL_TYPE;
  open: boolean;
  data?: any;
  onClose: () => void;
  columnClass?: string;
  redirectToOnClose?: string;
};

import React, { useState, useRef, useEffect } from 'react';
import Router, { useRouter } from 'next/router';
import styled from 'styled-components';
import Image from 'next/image';
import dynamic from 'next/dynamic';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { motion, AnimatePresence } from 'framer-motion';
import { useRecoilState } from 'recoil';
import {
  filterConfigState, registerState, sortConfigState,
} from 'recoils';
import { GetServerSideProps } from 'next';
import Head from 'next/head';
import { Divider, Col } from 'antd';
import Link from 'next/link';
import isEmpty from 'lodash/isEmpty';
import { initialValue as sortConfigInitialValue } from '@/recoils/modules/sort-config';
import { initialValue as filterConfigInitialValue } from '@/recoils/modules/filter-config';

import useSystemStatus from '@/hooks/useSystemStatus';
import { CheckPhoneResponse, CHECK_PHONE_RESULT, useCheckPhone } from '@/hooks/useCheckPhone';

import { LoginResponse, LOGIN_STATUS } from '@/services/login';
import { sleep } from '@/utils/time';

import {
  Button, BUTTON_TYPE,
} from '@/components/Button/CommonButton/CommonButton';
import { ALERT_MODAL_TYPE } from '@/components/Modal/AlertModal/type';
import { InputDigitHandles } from '@/components/InputDigit/InputDigit.type';
import InputDigit from '@/components/InputDigit/InputDigit';
import { COOKIES_KEY } from '@/utils/cookies';
import useReferrer from '@/hooks/useReferrer';
import { IMAGE_FOLDER_PATH, TWITTER_OFFICIAL_URL } from '@/models/buildtime-constant';
import { useLogin } from '@/hooks/useLogin';
import { useProfile } from '@/hooks/useProfile';
import toast, { TOAST_TYPE } from '@/utils/toast';
import { checkPsCode } from '@/services/profile';
import useStorage from '@/hooks/useStorage';
import { withServersideNonAuth } from '@/server/authorize';
import backButtonState from '@/recoils/modules/backButton';
import { useInbox } from '@/hooks/useInbox';
import SeoImage, { FULL_WIDTH, HALF_WIDTH } from '@/components/Seo/SeoImage';
import { useResponsiveState } from '@/hooks/useResponsive';
import { useAuth } from '@/hooks/useAuth';
import { useNavigate } from '@/hooks/useNavigate';
import { ForgetPhoneButton } from '@/components/Button/button.style';
import { screen } from '@/utils/responsive';

export type LoginPageProps = {
  lineIdToken: string;
  existingPhone: string;
  existingPin: string;
  isReferrerValid: boolean;
  referrer: string;
  registerClosed: boolean
};

const InputPhone = dynamic(() => import('@/components/InputPhone/InputPhone'), { ssr: false });
const TextLink = dynamic(() => import('@/components/Button/TextLink/TextLink'), { ssr: false });
const LineButton = dynamic(() => import('@/components/Button/LineButton/LineButton'), { ssr: false });
const AlertContactModalV2 = dynamic(() => import('@/components/Modal/AlertContactModal/AlertContactModalV2'), { ssr: false });
const AlertModalV2 = dynamic(() => import('@/components/Modal/AlertModal/AlertModalV2'), { ssr: false });
const AlertConfirmModal = dynamic(() => import('@/components/Modal/AlertConfirmationModal/AlertConfirmationModalV2'), { ssr: false });

const LoginContent = styled.section`
   b {
    color:${({ theme }) => theme['primary-400']};
  }
`;
const LoginDivider = styled.div`
  color: ${({ theme }) => theme['primary-200']};
  margin: 12px auto;
  display: grid;
  grid-template-columns: 1fr min-content 1fr;
  align-items: center;
  opacity: 0.5;
  &::before, &::after {
    content: '';
    border-bottom: 1px solid ${({ theme }) => theme['primary-200']};
  }
  span {
    text-align: center;
    padding: 0 8px;
    font-size: 18px;
  }
`;
const LoginHeader = styled.h3`
  text-align: center;
  font-size: 42px;
  @media ${screen.lg} {
    font-size: 64px;
  }
`;

export default function Login({
  lineIdToken = '',
  existingPhone = '',
  existingPin = '',
  referrer,
  registerClosed,
  isReferrerValid: isReferrerValidProps,
}: LoginPageProps) {
  const hasLineIdToken = lineIdToken.length > 0;
  const hasExistingPhone = existingPhone.length > 0;
  const hasExistingPin = existingPin.length > 0;
  const showLoginLine = !hasLineIdToken;
  const showLoginPhone = !hasExistingPhone || !hasExistingPin;
  const router = useRouter();
  const { navigateTo } = useNavigate();
  const { isMD } = useResponsiveState();
  const { setItem } = useStorage();
  const { callLogin } = useLogin();
  const { t } = useTranslation(['common', 'login', 'modal', 'register', 'lobby']);
  const { refreshSystemStatus } = useSystemStatus();
  const auth = useAuth();
  const [, setRegister] = useRecoilState(registerState);
  const [, setBackButton] = useRecoilState(backButtonState);
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [disabled, setDisabled] = useState(true);
  const [isLoginPinStep, setIsLoginPinStep] = useState(false);
  const [, setFilterConfigState] = useRecoilState(filterConfigState);
  const [, setSortConfigState] = useRecoilState(sortConfigState);
  const { callLinkLineWithoutAuth } = useProfile();
  const modalCallbackRef = useRef<() => void>(() => { });
  const digiInputRef = useRef<InputDigitHandles>(null);
  const { saveReferrerCookie } = useReferrer();
  const { callCheckPhone } = useCheckPhone();
  const { disconnectInboxSocket } = useInbox();
  const alertConfirmModal = useModal(NiceModal.create(AlertConfirmModal));
  const alertModal = useModal(NiceModal.create(AlertModalV2));
  const alertContactModal = useModal(NiceModal.create(AlertContactModalV2));

  const getValidPhoneNumber = () => {
    let checkPhoneNumber = phoneNumber;
    if (phoneNumber.length < 10) {
      checkPhoneNumber = `0${phoneNumber}`;
    }
    return checkPhoneNumber;
  };

  const onPhoneNumberChange = (val: string, isInvalid: boolean) => {
    setDisabled(isInvalid);
    setPhoneNumber(val);
  };

  const onCloseModal = () => {
    modalCallbackRef.current();
  };

  const showContactModal = () => {
    alertContactModal.show({ onClose: onCloseModal });
  };
  const showAlertModal = (type: ALERT_MODAL_TYPE) => {
    alertModal.show({ type, onClose: onCloseModal });
  };

  const goToTop = async () => {
    await sleep(0.5);
    window.scroll(0, 0);
    if (window.screenY > 0) {
      // NOTE : Make sure with scroll delay
      await sleep(0.2);
      window.scroll(0, 0);
    }
  };

  const redirectoToRegisterPage = (response: CheckPhoneResponse) => {
    setRegister({
      phoneNumber: getValidPhoneNumber(),
      otpCode: response.data.otp_ref_code,
      otpToken: response.data.otp_token,
    });
    router.push('/register');
  };
  const checkPhoneNumberStatus = async (response: CheckPhoneResponse) => {
    let redirectRegisterTimeout: NodeJS.Timeout;
    if (!response?.data?.phone_number_status || isEmpty(response?.data?.phone_number_status)) {
      showAlertModal(ALERT_MODAL_TYPE.UNEXPECTED_BEHAVIORS);
      return;
    }
    switch (response.data.phone_number_status) {
      case CHECK_PHONE_RESULT.REGISTER:
        if (hasLineIdToken) {
          await router.push('/login', '/login', { scroll: true });
          alertConfirmModal.show({
            hideOnClose: true,
            header: 'สมัครสมาชิกไม่สำเร็จ',
            content: 'ไม่พบบััญชีผู้ใช้ที่สมัครด้วยหมายเลขโทรศัพท์นี้',
            buttonConfirmText: 'ตกลง',
          });
          return;
        }
        // go to register page...
        showAlertModal(ALERT_MODAL_TYPE.OTP_SENT);
        redirectRegisterTimeout = setTimeout(() => {
          alertModal.hide();
          redirectoToRegisterPage(response);
        }, 3000);
        modalCallbackRef.current = () => {
          clearTimeout(redirectRegisterTimeout);
          redirectoToRegisterPage(response);
        };
        break;
      case CHECK_PHONE_RESULT.ACTIVE:
        // go to relogin...
        setIsLoginPinStep(true);
        setBackButton({
          onBack: () => {
            setIsLoginPinStep(false);
            setPhoneNumber('');
          },
        });
        goToTop();
        break;
      case CHECK_PHONE_RESULT.ERROR:
        // show block otp modal
        showAlertModal(ALERT_MODAL_TYPE.TOO_MANY_OTP_REQUEST_OR_ATTEMPT);
        break;
      case CHECK_PHONE_RESULT.BLOCKED:
        // show block pin modal
        showAlertModal(ALERT_MODAL_TYPE.PIN_LOCKED);
        modalCallbackRef.current = () => {
          Router.push(
            { pathname: '/reset-pin', query: { phone: phoneNumber } },
            '/reset-pin',
          );
        };
        break;
      case CHECK_PHONE_RESULT.DISABLED:
      case undefined: // for case existing user and inactive user
        // show contact modal
        showContactModal();
        break;
      default:
        // show unexpected modal
        showAlertModal(ALERT_MODAL_TYPE.UNEXPECTED_BEHAVIORS);
    }
  };

  const checkPhoneHandler = async () => {
    setDisabled(true);
    const checkPhoneNumber = getValidPhoneNumber();
    const currentStatus = await refreshSystemStatus();
    const response = await callCheckPhone({ phone: checkPhoneNumber })
      .catch(() => showAlertModal(ALERT_MODAL_TYPE.UNEXPECTED_BEHAVIORS));

    if (currentStatus?.register && response?.data?.phone_number_status === CHECK_PHONE_RESULT.REGISTER) {
      // show close register modal
      showAlertModal(ALERT_MODAL_TYPE.CLOSE_REGISTER);
      setDisabled(false);
      return;
    }

    if (response) {
      setPhoneNumber(checkPhoneNumber);
      checkPhoneNumberStatus(response);
    }
    setDisabled(false);
  };

  const setupAuthorization = (response: LoginResponse) => {
    setSortConfigState(sortConfigInitialValue);
    setFilterConfigState(filterConfigInitialValue);
    auth.authorize({
      cfid: response.cfid,
      uid: response.uid,
      customerCode: response.CustomerCode,
    });
  };

  const checkLoginStatus = async (response: LoginResponse) => {
    const status = response.Status;
    switch (status) {
      case LOGIN_STATUS.SUCCESS:
        if (hasLineIdToken) {
          const linkLineResponse = await callLinkLineWithoutAuth({
            line_id_token: lineIdToken,
            user_code: response.UserUid,
            bearer_token: response.cfid,
          });
          if (linkLineResponse.status === 'SUCCESS') {
            setupAuthorization(response);
          } else {
            alertContactModal.show({
              content: linkLineResponse.service_message,
            });
          }
          return;
        }
        setupAuthorization(response);
        setBackButton({ onBack: null });
        break;
      case LOGIN_STATUS.WRONG_PASSWORD:
        digiInputRef.current?.error(t`login:loginPin.inputValidate`);
        break;
      case LOGIN_STATUS.INACTIVE:
      case LOGIN_STATUS.BLOCK:
      case undefined:
        // show block modal
        digiInputRef.current?.error('');
        showContactModal();
        modalCallbackRef.current = () => {
          setIsLoginPinStep(false);
          setPhoneNumber('');
        };
        break;
      case LOGIN_STATUS.BLOCK_PIN:
        // show block pin modal
        digiInputRef.current?.error('');
        showAlertModal(ALERT_MODAL_TYPE.PIN_LOCKED);
        modalCallbackRef.current = () => {
          Router.push(
            { pathname: '/reset-pin', query: { phone: phoneNumber } },
            '/reset-pin',
          );
        };
        break;
      default:
        digiInputRef.current?.error('');
        showAlertModal(ALERT_MODAL_TYPE.UNEXPECTED_BEHAVIORS);
        modalCallbackRef.current = () => { };
    }
  };

  const loginPinHandler = async (pin: string) => {
    setDisabled(true);
    refreshSystemStatus();

    try {
      const response: LoginResponse = await callLogin({ phoneNumber, pin });
      if (response.Status !== LOGIN_STATUS.CLIENT_EXCEPTION) {
        if (response) checkLoginStatus(response);
      } else {
        // eslint-disable-next-line @typescript-eslint/no-throw-literal
        throw ALERT_MODAL_TYPE.UNEXPECTED_BEHAVIORS;
      }
    } catch (_error) {
      digiInputRef.current?.error('');
      showAlertModal(ALERT_MODAL_TYPE.UNEXPECTED_BEHAVIORS);
    }
    setDisabled(false);
  };

  useEffect(() => {
    disconnectInboxSocket();
    saveReferrerCookie();
    if (!isReferrerValidProps) {
      NiceModal.show(NiceModal.create(AlertModalV2), { type: ALERT_MODAL_TYPE.REFERRER_NOT_VALID });
    }
    if (registerClosed) {
      showAlertModal(ALERT_MODAL_TYPE.CLOSE_REGISTER);
    }
    setItem('referrer', JSON.stringify({
      isValid: isReferrerValidProps,
      psCode: referrer,
    }), 'session');
  }, []);

  React.useEffect(() => {
    // NOTE : handle 2 auth, waiting both of them does not have token then do action
    // TODO : we should observe only 1 auth
    if (auth.isAuthorize) {
      if (hasLineIdToken) {
        toast('ดำเนินการสำเร็จ', { autoClose: 6000 }, TOAST_TYPE.SUCCESS);
        router.push('/profile', '/profile', { scroll: true });
        return;
      }
      navigateTo('/lobby?toastWelcome=true', '/lobby');
    }
  }, [auth.isAuthorize]);

  return (
    <>
      <Head>
        <title>ทางเข้าpg สมัครสมาชิก เข้าสู่ระบบ ผ่านมือถือ</title>
        <meta name="title" content="ทางเข้าpg สมัครสมาชิก เข้าสู่ระบบ ผ่านมือถือ" />
        <meta property="og:title" content="ทางเข้าpg สมัครสมาชิก เข้าสู่ระบบ ผ่านมือถือ" />
        <meta property="twitter:title" content="ทางเข้าpg สมัครสมาชิก เข้าสู่ระบบ ผ่านมือถือ" />

        <meta name="description" content="ทางเข้าpg สมัครใหม่หรือเข้าสู่ระบบ ผ่านเว็บและมือถือ อัตโนมัติ ทดลองเล่นpg และค่ายสล็อตอื่นๆอีกเพียบ ได้ 24 ชั่วโมง สมัครง่าย ฝาก50รับ100 ได้เลยทันที" />
        <meta property="og:description" content="ทางเข้าpg สมัครใหม่หรือเข้าสู่ระบบ ผ่านเว็บและมือถือ อัตโนมัติ ทดลองเล่นpg และค่ายสล็อตอื่นๆอีกเพียบ ได้ 24 ชั่วโมง สมัครง่าย ฝาก50รับ100 ได้เลยทันที" />
        <meta property="twitter:description" content="ทางเข้าpg สมัครใหม่หรือเข้าสู่ระบบ ผ่านเว็บและมือถือ อัตโนมัติ ทดลองเล่นpg และค่ายสล็อตอื่นๆอีกเพียบ ได้ 24 ชั่วโมง สมัครง่าย ฝาก50รับ100 ได้เลยทันที" />
      </Head>
      <LoginContent className="container ps-login-content space-top-1">
        <AnimatePresence>
          {isLoginPinStep ? (
            <motion.div
              initial="initial"
              animate="animate"
              exit="exit"
              variants={{
                initial: { opacity: 0, x: 300 },
                animate: { opacity: 1, x: 0 },
                exit: { opacity: 0, x: -300 },
              }}
              transition={{ bounce: 0 }}
            >
              <section className="row justify-content-center">
                <h3
                  id="login-pin-title"
                  data-testid="login__pin-title"
                  className="ps-login__title text-center"
                >
                  {t`login:loginPin.title`}
                </h3>
                <p
                  id="login-pin-description"
                  data-testid="login__pin-description"
                  className="text-center my-0 mt-1"
                >
                  {t`login:loginPin.inputLabel`}
                </p>
                <div className="col-12">
                  <InputDigit
                    id="login__pin-input"
                    ref={digiInputRef}
                    onComplete={loginPinHandler}
                    disabled={disabled}
                  />
                </div>
                <div className="col-4 justify-center pb-5">
                  <TextLink
                    id="login__forgot-pin-link"
                    disabled={disabled}
                    className="ps-forget-pin-link text-center mx-auto"
                    onClick={() => Router.push(
                      { pathname: '/reset-pin', query: { phone: phoneNumber } },
                    )}
                  >
                    {t`forgetPin`}
                  </TextLink>
                </div>
              </section>
            </motion.div>
          ) : (
            <form className="ps-login row" onSubmit={(e) => e.preventDefault()}>
              <LoginHeader data-testid="login-title">{t`login:checkPhone.title`}</LoginHeader>
              <Image
                className="ps-login__banner-image col-12 col-md-6 col-lg-4"
                src={`${IMAGE_FOLDER_PATH}/login/login-register-pig.webp`}
                blurDataURL={`${IMAGE_FOLDER_PATH}/login/login-register-pig.webp`}
                placeholder="blur"
                width={262}
                height={240}
                priority
                alt="login-title-pig"
                quality={70}
              />
              {showLoginPhone && (
                <>
                  <div className="col-12 col-md-6 col-lg-4">
                    <InputPhone
                      id="login-checkphone-input"
                      className="ps-login__phone-input"
                      title={t`login:checkPhone.inputLabel`}
                      placeholder={t`login:checkPhone.inputPlaceholder`}
                      errorMessage={t`login:checkPhone.inputValidate`}
                      onInputChange={onPhoneNumberChange}
                      isError={phoneNumber.length > 0 && phoneNumber.trim().length === 0}
                    />
                  </div>
                  <Button
                    id="checkphone-next-button"
                    type={BUTTON_TYPE.CTA_1}
                    className="ps-login__button-submit"
                    disabled={disabled}
                    onClick={checkPhoneHandler}
                    nativeType="submit"
                    overrideHeight={40}
                  >
                    {t`continue`}
                  </Button>
                  <ForgetPhoneButton
                    onClick={() => {
                      alertContactModal.show({
                        header: 'ต้องการเปลี่ยนแปลงข้อมูล',
                      });
                    }}
                  >
                    ลืมเบอร์โทรศัพท์ ?
                  </ForgetPhoneButton>
                </>
              )}
              {showLoginLine && showLoginPhone && (
                <LoginDivider className="ps-login__divider col-12 col-md-6 col-lg-4" key="divider">
                  <span>{t`common:or`}</span>
                </LoginDivider>
              )}
              {showLoginLine && (
                <div className="col-12 col-md-6 col-lg-4" key="login-line">
                  <LineButton redirectPath="/line/verify" />
                </div>
              )}
            </form>
          )}
        </AnimatePresence>
        <br />
      </LoginContent>
      <LoginContent className="container ps-login-content space-bottom-1">
        <Divider />
        <SeoImage.Wrapper align="middle">
          <SeoImage.Image
            col={isMD ? HALF_WIDTH : FULL_WIDTH}
            img={{
              height: isMD ? 350 : 200,
              src: `${IMAGE_FOLDER_PATH}/seo/login_aw_1.webp`,
              alt: 'pgslot-24-hrs',
            }}
          />
          <Col span={24} md={12}>
            <h1>
              ทางเข้าpg สมัครสมาชิกเล่นสล็อตง่ายกว่าที่คิด
            </h1>
            <div>
              สำหรับคนที่เคยเข้า
              <b> pgslot to </b>
              แล้วอยากรู้ว่า
              <b> ทางเข้าpg </b>
              สมัครสมาชิกเล่นสล็อตง่ายกว่าที่คิด จริงหรือไม่ เราขอท้าพิสูจน์ให้คุณลอง
              <b> ทางเข้าpg slot เว็บตรง </b>
              ที่ PIGSPIN แล้วจะรู้ว่า
              <b> slotpg ทางเข้า มือถือ </b>
              ของที่นี่ จะใช้เครือข่ายมือถือ หรือเน็ตบ้านก็เข้าง่าย ทุกเครือข่าย ถึงจะสมัครสมาชิกใหม่ที่
              <b> ทางเข้าpg </b>
              แต่ก็ไม่จำเป็นต้องจ่ายเงินที่
              <b> ทางเข้าpgทดลองเล่น </b>
              เพราะ PIGSPIN ใจป้ำ แจกเครดิต100ให้สมาชิกใหม่ทุกคน ได้ทดลอง
              <b> pg เข้าสู่ระบบ </b>
              ฟรี! และมีแจกโบนัสเพิ่ม กำไรก้อนใหญ่ สำหรับทุกการเติมเงินอีก10% แถมยังมีเกมให้เลือกอีกเพียบ
              <b> ทางเข้าpg </b>
              อัตโนมัติ เข้าง่าย โหลดไว ไลน์สล็อตแตกหนักตลอด 24 ชั่วโมง!
            </div>
          </Col>
        </SeoImage.Wrapper>
        <Divider />
        <h2>
          มั่นใจกว่า กับทางเข้าpgทดลองเล่น ไม่ผ่านเอเย่นต์ เว็บตรง ล่าสุด
        </h2>
        <div>
          เพื่อนๆที่ยังเล่นสล็อตผ่านเอเย่นต์เลิกเถอะ! มามั่นใจกับ
          <b> ทางเข้าpgทดลองเล่น </b>
          ไม่ผ่านเอเย่นต์ เว็บตรง ล่าสุด ดีกว่า! เพราะเว็บตรงที่มี
          <b> ทางเข้าpg </b>
          อัตโนมัติ นอกจากจะไม่มีค่าธรรมเนียมแอบแฝงแล้ว ยังแถมโบนัสฟรีให้มากถึง 10% ให้ทุกๆการเติมเงิน ทุกแพลตฟอร์ม
          ทุกช่องทาง ไม่มีขั้นต่ำ เพียงแค่สมัครสมาชิกผ่าน
          <Link href="/login"> ทางเข้า pg </Link>
          พร้อมรับสิทธิพิเศษไม่อั้น ได้เลยทันที ทดลองเล่นเกมได้หลายค่าย ทั้งยังมี
          <b> ทางเข้าpg soft </b>
          เว็บตรง ล่าสุด ให้เลือกมากกว่า พร้อมด้วยบริการแบบออโต้ ครบวงจร ทันสมัย จะเล่นผ่านเว็บหรือมือถือ ก็ไม่มีสะดุด
          มั่นใจได้เลย ชัวร์กว่าเว็บอื่นแน่นอน!
        </div>
        <Divider />
        <h2>
          คำถามที่พบบ่อยกับการสมัครทางเข้าpg
        </h2>
        <div>
          ถ้าเพื่อนๆเคยมีคำถามกับ
          <b> pgslot to pg888th </b>
          แต่ยังไม่หายข้องใจ เราได้เลือกประเด็นที่น่าสนใจ คำถาม ที่พบบ่อยกับการสมัคร
          <b>ทางเข้าpg </b>
          เกี่ยวกับการเข้าสู่ระบบ
          <b> ทางเข้าpg </b>
          ผ่านมือถือ และคอมพิวเตอร์ บนเว็บตรงยอดฮิต ล่าสุด รวมถึงคำถาม
          <b> ทางเข้าpgทดลองเล่น </b>
          พร้อมกับหาคำตอบ ทุกระบบ ครบวงจร มาให้เพื่อนๆ ได้หายสงสัยกันแล้ว
        </div>
        <Divider />
        <h3>
          ทางเข้าpg slot เว็บตรง ที่เชื่อถือได้ ดูยังไง?
        </h3>
        <div>
          วิธีดู
          <b> ทางเข้าpg slot เว็บตรง </b>
          ที่เชื่อถือได้ ดูยังไง? คำตอบคือ เพื่อนๆควรเลือกเว็บตรง ไม่ผ่านเอเย่นต์ ที่มี
          <b> ทางเข้าpgทดลองเล่น </b>
          ไม่คิดค่าธรรมเนียม หรือ
          <b> pg เข้าสู่ระบบ </b>
          สมัครง่าย ที่มีโปรฯแจกจริง ลองเล่นได้ทันที เช่น ฝาก50รับ100 ก็ได้เงินจริง ถอนเงินไม่มีขั้นต่ำ หรือ
          <b> ทางเข้าpg soft </b>
          ที่มีแจกเครดิต สมาชิกใหม่เล่นฟรี แบบอัตโนมัติ ทันทีที่
          <Link href="/login"> สมัครสมาชิก </Link>
          โดยไม่ยุ่งยาก นอกจากนี้
          <b> slotpg ทางเข้า มือถือ</b>
          เว็บตรง ก็ต้องมีระบบวอลเลท ฝากถอนง่าย ทันสมัย ทุกธุรกรรมปลอดภัย ไม่มีเงื่อนไขขั้นต่ำ โบนัสแจกเยอะ แตกง่าย
          ได้สิทธิ์ลุ้นรางวัลใหญ่ รวมถึงต้องมีเจ้าหน้าที่คอยดูแล ให้บริการลูกค้า ติดต่อได้ทุกเวลา ทุกปัญหามีคำตอบ มั่นใจได้เลยที่
          PIGSPIN มีครบ ตอบโจทย์นักเดิมพันทุกคนแน่นอน!
        </div>
        <Divider />
        <h3>
          เข้าสู่ระบบทางเข้าpg ต้องเติมเงินก่อนไหม?
        </h3>
        <div>
          หลายคนกังวลว่า
          <b> เข้าสู่ระบบ ทางเข้าpg </b>
          ต้องเติมเงินก่อนไหม? สำหรับที่ PIGSPIN มั่นใจได้เลย เพียงแค่
          <Link href="/login"> สมัครสมาชิก </Link>
          รับเลยทันที 100 เครดิต ให้ทดลองเล่นสล็อตฟรี สมาชิกใหม่ ตรงไปที่
          <Link href="/"> ทางเข้าpg soft </Link>
          ได้เลย สมัครง่าย เงื่อนไขน้อย ไม่มีค่าธรรมเนียม แค่กรอกข้อมูลและยืนยันตัวตนตามขั้นตอน ก็เริ่มหมุนสล็อตได้แบบออโต้
          เล่นเกมไหนก็ได้ ไม่ว่าจะคลิก
          <b> ทางเข้าpg </b>
          ทั้งบนมือถือ หรือเล่นผ่านคอม ก็เชื่อมข้อมูลกันได้หมด หรือถ้าจะเติมเงินก็สะดวกสบาย ทุกๆการเติมเงิน ไม่กำหนดขั้นต่ำ
          คุ้มที่สุด มีแจกโปรฯเติมเงินให้อีก10% และยังมีโปรฯที่น่าสนใจอื่นๆ อีกมากมาย อัพเดทได้ที่
          <b> ทางเข้าpg slot เว็บตรง </b>
          มีเกมให้เลือกเพียบ แตกง่าย ลุ้นรับโบนัสก้อนโต แจกจริงทั้งวัน พลาดไม่ได้!
        </div>
        <Divider />
        <h3>
          คำถามเพิ่มเติมกับทางเข้าslotpg
        </h3>
        <div>
          หากนักเดิมพันมีคำถามเพิ่มเติมกับ
          <b> ทางเข้า pg </b>
          slot สามารถเข้าไปที่หน้า
          <Link href="/faq"> FAQ </Link>
          ซึ่งทางแอดมินได้รวบรวมคำถามที่พบบ่อยกับการใช้งาน
          <b> ทางเข้าpgทดลองเล่น ทางเข้าpg slot เว็บตรง </b>
          โดยจัดเป็นหมวดหมู่ มีคำตอบชัดเจน กระชับ อ่านง่าย ไม่ว่าจะเป็นคำถามเกี่ยวกับ ขั้นตอนการสมัครสมาชิก
          การเข้าสู่ระบบ การเติมเงิน ฝาก-ถอนออโต้ ไม่มีขั้นต่ำ ระบบวอลเลท โปรแกรมแนะนำเพื่อน การทำภารกิจ
          การแลกรางวัล สิทธิประโยชน์สำหรับสมาชิก โปรโมชั่นเด็ดๆ และคำถามอื่นๆ อีกมากมาย
        </div>
        <Divider />
        <h2>
          ทางเข้าpgมือถือ ครบวงจร บริการดีที่ใครๆ ก็บอกต่อ
        </h2>
        <div>
          เมื่อคลิกที่
          <Link href="/"> ทางเข้าpgมือถือ </Link>
          PIGSPIN เพื่อนๆก็สามารถเข้าสู่ระบบ ครบวงจร ได้ทันที เพื่อรับบริการดีที่ใครๆ ก็บอกต่อ เพราะทุกการชนะเงินรางวัล
          ถอนเป็นเงินสดได้จริง ล่าสุด!
          <b> ที่ pg เข้าสู่ระบบ </b>
          มีเกมเพิ่มเข้ามาอีกเพียบ
          <b> pg888th </b>
          ทั้งเกมจากค่ายดัง pg soft หรือเกมสล็อตมันๆจาก Joker, EVO, Play n Go ทุกเกมแตกง่าย ภาพสวย ซาวด์ดี
          เล่นสนุก เร้าใจ จัดหนัก จัดเต็มบน
          <b> ทางเข้าpgทดลองเล่น </b>
          แถมยังมีแจกเครดิตฟรี ไม่ต้องแชร์ ทุกธนาคารในระบบ ฝาก-ถอนออโต้ ได้ทุกเวลา ไม่มีขั้นต่ำ เว็บตรง PIGSPIN
          จึงกลายเป็นเว็บสล็อตมาแรง ที่มีคนบอกต่อให้เพื่อนมาเล่นมากที่สุด เพราะยิ่งชวนเพื่อนมาก ก็ยิ่งได้โบนัสมากถึง 3 ต่อ
          คุ้มสุดคุ้ม กำไรมหาศาล
        </div>
        <SeoImage.Wrapper>
          <SeoImage.Image
            img={{
              src: `${IMAGE_FOLDER_PATH}/seo/login_aw_2.webp`,
              alt: 'pgslot-login-best-service',
            }}
          />
        </SeoImage.Wrapper>
        <Divider />
        <h2>
          ลืมเบอร์มือถือ เข้าสู่ระบบ pg สล็อต อย่างไร?
        </h2>
        <div>
          หากเพื่อนๆ มีปัญหาเกี่ยวกับเบอร์มือถือ แต่ไม่รู้ว่าจะ
          <b> เข้าสู่ระบบ pg สล็อต </b>
          อย่างไร หรือสามารถคลิกที่
          <b> ทางเข้า pg </b>
          ได้เลย โดยไม่ว่าจะเป็นการลืมเบอร์ที่ใช้เข้าสู่ระบบ
          <b> ทางเข้าpg slot เว็บตรง </b>
          กดข้อมูลแล้วไม่ได้รับรหัสOTP เบอร์โทรศัพท์โดนระงับจากเครือข่าย หรือเบอร์มือถือโดนระงับจากระบบใดใด ก็ให้คลิกที่
          <b> ทางเข้าpg soft </b>
          โดยสังเกตที่ ด้านล่างช่องกรอกข้อมูล
          <b> pg เข้าสู่ระบบ </b>
          จะมีปุ่ม “ลืมเบอร์โทรศัพท์” ให้เพื่อนๆ กดเข้าไปที่ปุ่มนี้ แล้วระบบจะขึ้นป๊อบอัพข้อมูล ช่องทางการติดต่อเจ้าหน้าที่
          พร้อมตอบคำถาม และให้บริการตลอด 24 ชั่วโมง
        </div>
        <Divider />
        <h3>
          ช่องทางติดต่อแอดมินเพื่อเข้าสู่ระบบ ทางเข้าpg
        </h3>
        <div>
          สมาชิกของเว็บสล็อตออนไลน์ PIGSPIN สามารถติดต่อแอดมินเพื่อ เข้าสู่ระบบ
          <b> ทางเข้าpg </b>
          ได้ทุกเวลา โดยมีทั้งช่องทางติดต่อผ่าน Social Media หรือ E-mail ที่ใช้ได้ทั้งบนมือถือและคอมพิวเตอร์ ดังนี้
        </div>
        <br />
        <div>
          &emsp;1.
          <b> ช่องทางเพิ่มเพื่อนใน </b>
          <Link href={`${window.location.origin}/line`}> LINE </Link>
          <b> Application ผ่านมือถือ หรือคอมพิวเตอร์ </b>
          <br />
          เมื่อเพื่อนๆ คลิกที่ LINE ตรงหน้า
          <b> ทางเข้า pg </b>
          เว็บตรง จะมี Qr Code ปรากฏขึ้นมา เพื่อให้เพื่อนๆ สแกนเพิ่ม LINE Official Account ของ PIGSPIN
          แบบอัตโนมัติ ให้ผู้เล่นสามารถแชทคุยกับเจ้าหน้าที่ได้โดยตรง ซึ่งจะแชทผ่านมือถือ หรือคอมพิวเตอร์ก็ได้
          แล้วแอดมินจะช่วยดำเนินการ แก้ไขปัญหา พร้อมให้คำแนะนำอย่างรวดเร็ว ทั้งการกู้คืนเบอร์
          หรือการเปลี่ยนแปลงข้อมูลเบอร์โทร สอบถามแอดมินได้ทุกคำถาม ทุกกรณี เพียงแค่สมาชิก ยืนยันตัวตนตามขั้นตอน
          มั่นใจได้ว่าเครดิตที่สะสมไว้อยู่ครบ เพราะที่ PIGSPIN เป็นสล็อตเว็บตรง มีระบบวอเลทที่ปลอดภัยสูง
        </div>
        <br />
        <div>
          &emsp;2.
          <b> ส่งข้อความใน Direct Message หรือส่ง Mention มาทาง </b>
          <Link href={TWITTER_OFFICIAL_URL}> Twitter </Link>
          <br />
          สำหรับผู้เล่นที่ชอบติดตามข่าวสารใน Twitter เราก็มี
          <b> ทางเข้าpg slot เว็บตรง </b>
          PIGSPIN ด้วยเช่นกัน โดยเพื่อนๆสามารถติดต่อแอดมิน มาทางDMโดยตรง หรือทางเมนชั่น
          แล้วแอดมินจะทำการติดต่อกลับไปทางแอคเค้าท์ของเพื่อนๆ โดยเร็วที่สุด! นอกจากนี้
          แนะนำให้เพื่อนๆสายทวิตเตอร์กด follow PIGSPIN ไว้ เพราะที่นี่มีอัพเดทโปรโมชั่นตลอดเวลา! ใครรู้ก่อน ได้ก่อน!
        </div>
        <SeoImage.Wrapper>
          <SeoImage.Image
            col={isMD ? {
              ...HALF_WIDTH,
              offset: isMD ? 6 : 0,
            } : FULL_WIDTH}
            img={{
              height: isMD ? 350 : 250,
              src: `${IMAGE_FOLDER_PATH}/seo/login_aw_3.webp`,
              alt: 'pgslot-team-24hrs',
            }}
          />
        </SeoImage.Wrapper>
        <br />
        <div>
          &emsp;3.
          <b> ติดต่อแอดมินทาง E-mail ที่ support@pigspin.asia </b>
          <br />
          หรือเพื่อนๆจะติดต่อแอดมินมาทาง E-mail ที่
          <b> support@pigspin.asia </b>
          ก็สามารถส่งอีเมลหาเราได้ตลอด 24 ชั่วโมง โดยข้อดีของอีเมลล์คือ หากเพื่อนๆต้องการส่งข้อมูล
          <b> ทางเข้า pg </b>
          เช่น ภาพโบนัสสล็อตที่เคยแคปเก็บไว้ หรือสลิปโอนเงิน ฝาก-ถอนไม่มีขั้นต่ำ หลายๆรูปในคราวเดียว
          เพื่อนๆก็สามารถแนบไฟล์ หรือเขียนข้อความได้เยอะ และยังมีความปลอดภัยด้านข้อมูล เป็นส่วนตัวสูง!
        </div>
        <br />
        <div>
          &emsp;4.
          <b> ติดต่อแอดมิน ผ่านระบบ แชท WebChat </b>
          <br />
          เว็บแชท WebChat ฟีเจอร์ใหม่ล่าสุดที่จะช่วยให้เพื่อนๆ สะดวกยิ่งขึ้นในการติดต่อทีมงาน เพียงมองหาสัญลักษณ์แชท
          บริเวณด้านขวาล่างของเว็บไซต์ เพื่อน ๆ สามาถกดพูดคุย ติดต่อทีมงาน PIGSPIN ได้เลยทันที สะดวกแบบนี้
          มีแค่ทางเข้าPG ที่นี้ที่เดียว
        </div>
        <Divider />
        <h2>
          ทางเข้าpgsoft ล่าสุด พาไปทดลองเล่นสล็อตด้วยเครดิตฟรี
        </h2>
        <div>
          อยากเล่นสล็อตได้เงินจริง ทดลองเล่นสล็อตด้วยเครดิตฟรีที่
          <b> ทางเข้าpgsoft </b>
          ล่าสุด พร้อมรับโปรโมชั่นสุดพิเศษมากมายผ่าน
          <b> ทางเข้าpgทดลองเล่น </b>
          สนุกกับเกมสล็อตดังๆจากหลายค่าย โดยจะเล่นด้วย
          <b> slotpg ทางเข้า มือถือ </b>
          หรือเข้าผ่าน
          <b> ทางเข้าpg slot เว็บตรง </b>
          เติมเงินไม่มีขั้นต่ำ ทดลองเล่นpg จะลงทุนน้อย ลงทุนมาก ก็ลุ้นรับบิ๊กโบนัสจากสล็อตแตกง่ายได้แบบไม่อั้น
          เงินรางวัลโอนเข้าวอลเลท อัตโนมัติ ต้องเล่นที่ PIGSPIN เท่านั้น! ใครอยากได้เงินก้อนโตแบบมันส์ๆ อย่ารอช้า
          เข้าสู่ระบบ ได้ที่
          <Link href="/login"> ทางเข้า pg </Link>
          สมัครเลย!
        </div>
      </LoginContent>
    </>
  );
}

export const getServerSideProps: GetServerSideProps = withServersideNonAuth(async (context) => {
  const {
    req,
    locale,
    res,
    query,
  } = context;
  const setCookies = [`${COOKIES_KEY.LINE_STATE}=deleted; Max-Age=0;`];
  const {
    from = null,
    referrer = '',
    line_id_token, pin, phone,
    register_closed = false,
  } = query;
  const getLineData = () => {
    try {
      return JSON.parse(req.cookies?.line_data || '');
    } catch (e) {
      return null;
    }
  };
  const lineData = getLineData();
  if (lineData) {
    setCookies.push(`${COOKIES_KEY.LINE_DATA}=deleted; Max-Age=-1`);
  }
  res.setHeader('set-cookie', setCookies);
  let isReferrerValid = true;
  if (referrer) {
    const referrerUser = await checkPsCode(`${referrer}`);
    isReferrerValid = referrerUser.service_code === 'PIG-2008';
  }
  const props = {
    ...await serverSideTranslations(locale || 'th', ['common', 'login', 'lobby', 'modal', 'system-status', 'register']),
    data: {
      lineData,
    },
    isReferrerValid,
    referrer,
    lineIdToken: line_id_token || '',
    existingPin: pin || '',
    existingPhone: phone || '',
    loginStatus: null,
    registerClosed: register_closed,
  };
  return ({
    props,
  });
});

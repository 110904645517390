import { AxiosRequestConfig } from 'axios';
import { fetchWithJSON } from '@/utils/api/fetch';
import { pigspinApiInstance } from '@/utils/api/AxiosInstance';
import { BaseApiResponse, BaseResponse } from '@/services/service.type';
import { ProfileData } from './profile.type';

type ProfileResponse = BaseResponse & {
  data: ProfileData;
};

export type GetProfileParams = {
  query_by: string;
  id_card?: string;
  user_code?: string;
  phone_number?: string;
  phone_number_pattern: string;
  id_card_pattern: string;
  back_id_card_pattern: string;
  bank_account_number_10_digits_pattern: string;
  bank_account_number_12_digits_pattern: string;
};

export type ProfileRequest = GetProfileParams & {
  token: string;
};

export const getProfile = async (request: ProfileRequest): Promise<ProfileData> => {
  const { token, ...params } = request;
  const requestConfig: AxiosRequestConfig = {
    url: '/v1/user/me/profile',
    method: 'GET',
    headers: {
      authorisation: token,
    },
    params,
  };
  const result = await fetchWithJSON<ProfileResponse>(pigspinApiInstance, requestConfig);

  return result.data as ProfileData;
};

export type CheckPsCodeResponse = BaseApiResponse<any>;

export const checkPsCode = async (psCode: string): Promise<CheckPsCodeResponse> => {
  return fetchWithJSON<CheckPsCodeResponse>(pigspinApiInstance, {
    url: `v1/user/player-name/${psCode}/check`,
    method: 'GET',
  });
};

import Link from 'next/link';
import Image, { ImageProps } from 'next/legacy/image';
import {
  Col, ColProps,
  Row, RowProps,
} from 'antd';
import * as React from 'react';
import { useResponsiveState } from '@/hooks/useResponsive';
import { FunctionComponent } from '../type';

export type ISeoImageProps = {
  img: ImageProps;
  col?: ColProps;
  width?: 'rectangle' | 'semi-square';
  link?: string;
  textLink?: string;
};

const FULL_WIDTH: ColProps = {
  style: { textAlign: 'center' },
  span: 24,
};

const HALF_WIDTH: ColProps = {
  style: { textAlign: 'center' },
  xs: 24,
  sm: 24,
  md: 12,
};

const SeoImage: React.FunctionComponent<ISeoImageProps> = (props) => {
  const { isMD } = useResponsiveState();
  const image = (
    <Image
      layout="fill"
      objectFit="cover"
      style={{ borderRadius: 20, cursor: props.link ? 'pointer' : 'default' }}
      placeholder="blur"
      blurDataURL={`${props.img.src}`}
      {...props.img}
    />
  );
  const calHeight = React.useMemo(() => {
    if (props.img.height) return props.img.height;
    if (props.width === 'rectangle' && isMD) return 300;
    if (props.width === 'rectangle' && !isMD) return 120;
    if (isMD) return 300;
    return 250;
  }, [props.img.height, isMD, props.width]);
  const colProps = React.useMemo(() => {
    if (props.col) return props.col;
    if (props.width === 'rectangle') return FULL_WIDTH;
    return HALF_WIDTH;
  }, [props.col, props.width]);
  return (
    <Col {...colProps}>
      <div style={{ height: calHeight, position: 'relative' }}>
        {props.link ? <Link legacyBehavior href={props.link}><div>{image}</div></Link> : image}
      </div>
      {props.textLink && (
        <div style={{ margin: 10 }}>
          {props.link ? <Link href={props.link}>{props.textLink}</Link> : props.textLink}
        </div>
      )}
    </Col>
  );
};

SeoImage.defaultProps = {
  width: 'rectangle',
};

const SeoImageWrapper: FunctionComponent<RowProps> = (props) => {
  const { isMD } = useResponsiveState();
  return (
    <Row style={{ minWidth: '100%' }} gutter={isMD ? [16, 16] : undefined} {...props}>
      {props.children}
    </Row>
  );
};

export {
  HALF_WIDTH,
  FULL_WIDTH,
};
export default {
  Image: SeoImage,
  Wrapper: SeoImageWrapper,
};

import fetch from 'isomorphic-unfetch';
import { useRouter } from 'next/router';

export const useNavigate = () => {
  const router = useRouter();

  // NOTE : This will make redirect always go through server side
  const navigateTo = async (url: string, as?: string) => {
    // Make an HTTP request to the server-side route
    router.push(url, as);
    // const res = await fetch(url);
    // // Check if the response indicates a redirect
    // if (res.status === 307 || res.status === 308) {
    //   // Extract the redirected URL from the response headers
    //   const redirectUrl = res.headers.get('Location');

    //   // Perform client-side navigation to the redirected URL
    //   router.push(redirectUrl || '');
    // } else {
    //   // No redirect, update the current page
    //   router.replace(url || '', as);
    // }
  };

  return {
    navigateTo,
  };
};
